import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MarkerEditor, PolygonEditor, PolylineEditor, CircleEditor } from '@quanturban/map-core'
import { useQUMapValue } from '../quMap/QUMapContext'

type EditorType = 'marker' | 'polyline' | 'polygon' | 'circle'

type OverlayOptions = {
  marker?: any,
  polyline?: any,
  polygon?: any,
  circle?: any
}

type EditorOptions = {
  marker?: any,
  polyline?: any,
  polygon?: any,
  circle?: any
}

type EditorRef = MarkerEditor | PolygonEditor | PolylineEditor | CircleEditor

export interface Props {
  /** 覆盖物编辑类型，目前支持 点，线，面。更多类型支持 https://lbs.amap.com/api/jsapi-v2/documentation#%e5%b7%a5%e5%85%b7%e7%b1%bb */
  type: EditorType;
  /** 编辑的数据 */
  preData?: any;
  /** 被编辑覆盖物设置 */
  overlayOptions?: OverlayOptions;
  /** 编辑器参数设置 */
  editorOptions?: EditorOptions;
  /** 开启编辑器事件 */
  onOpen?: () => void;
  /** 关闭编辑器事件 */
  onClose?: () => void;
  /** 绘制下个覆盖物事件 */
  onNext?: () => void;
  /** 重置编辑器事件 */
  onReset?: () => void;
  /** 绘制完成事件 */
  onFinish?: (overlays: unknown) => void;
  /** 销毁编辑器事件 */
  onDestroy?: () => void;
}

/**
 * @description 绘制组件
 * @category Component
 */
const Editor = React.forwardRef<EditorRef, Props>((props, ref) => {
  const {
    type = 'marker',
    overlayOptions,
    onFinish,
    onOpen,
    onNext,
    onDestroy,
    preData
  } = props
  const quMap = useQUMapValue()
  const editor = useRef<EditorRef>()
  const [isReady, setReady] = useState(false)

  const createEditor = useCallback(() => {
    const events = {
      onOpen,
      onFinish,
      onNext,
      onDestroy
    }
    const initialProps = {
      quMap,
      events,
      overlayOptions: overlayOptions?.[type]
    }
    if (type === 'marker') {
      return new MarkerEditor(initialProps)
    } else if (type === 'polygon') {
      return new PolygonEditor(initialProps)
    } else if (type === 'polyline') {
      return new PolylineEditor(initialProps)
    } else if (type === 'circle') {
      return new CircleEditor(initialProps)
    } else {
      // eslint-disable-next-line no-console
      console.error('Error: Not supported editor type')
    }
  }, [type, overlayOptions, quMap, onFinish, onOpen, onNext, onDestroy])

  useEffect(() => {
    if (isReady) {
      return
    }

    async function init () {
      await quMap.addPlugins([
        'AMap.MouseTool',
        'AMap.GeoJSON',
        'AMap.PolygonEditor',
        'AMap.PolylineEditor',
        'AMap.CircleEditor'
      ])

      setReady(true)
    }

    init()
  }, [quMap, isReady])

  useEffect(() => {
    if (!isReady) {
      return
    }

    editor.current?.close(true)

    editor.current = createEditor()
    editor.current?.open()

    if (preData) {
      editor.current?.load(preData)
    }

    if (ref && editor.current) {
      (ref as React.MutableRefObject<EditorRef>).current = editor.current
    }
  }, [isReady, ref, preData, createEditor])

  useEffect(() => {
    return () => {
      editor.current?.destroy()
    }
  }, [])

  return <></>
})

export default Editor

// TODO:
// 1. extract get plugin function
// 2. add type
// 3. extract createEditor to core lib
// 4. more props callback
